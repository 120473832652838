// extracted by mini-css-extract-plugin
export var CarrouselWrapper2 = "Daze-module--CarrouselWrapper2--j+ZjO";
export var DescriptionWrapper = "Daze-module--DescriptionWrapper--vOSiv";
export var DownloaderWrapper = "Daze-module--DownloaderWrapper--eSbtC";
export var EventWrapper = "Daze-module--EventWrapper--QqWkn";
export var ImageWrapper = "Daze-module--ImageWrapper--OGaaY";
export var ImagesWrapper = "Daze-module--ImagesWrapper--EneJr";
export var MobileTile = "Daze-module--MobileTile--lNd0B";
export var PdpWrapper = "Daze-module--PdpWrapper--xQK6T";
export var PhotosWrapper = "Daze-module--PhotosWrapper--m2Y-5";
export var TitleWrapper = "Daze-module--TitleWrapper--NOjWi";
export var Wrapper = "Daze-module--Wrapper--UdPdo";