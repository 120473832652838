import * as React from "react";
import {
  Wrapper,
  EventWrapper,
  PdpWrapper,
  DescriptionWrapper,
  DownloaderWrapper,
  PhotosWrapper,
CarrouselWrapper2,
  TitleWrapper,
  ImageWrapper,
  MobileTile
} from "./Daze.module.css";
import { GrayTitle } from "./../../../../css/Title.module.css";
import Carrousel from "../../../../components/Carrousel";
import Title from "../../../../components/Title";
import Carousel from 'react-multi-carousel';

import DazePdf from "../../../../res/Daze/PresseDaze.pdf"
import Daz from "../../../../res/Daze/exposition/daze.jpg"
import DazePdp from "./../../../../res/Daze/exposition/daze-tile.png"
import Daze0 from "../../../../res/Daze/exposition/daze0.jpg"
import Daze1 from "../../../../res/Daze/exposition/daze1.jpg"
import Daze2 from "../../../../res/Daze/exposition/daze2.jpg"
import Daze3 from "../../../../res/Daze/exposition/daze3.jpg"
import Daze4 from "../../../../res/Daze/exposition/daze4.jpg"
import Daze5 from "../../../../res/Daze/exposition/daze5.jpg"
import Daze6 from "../../../../res/Daze/exposition/daze6.jpg"
import Daze7 from "../../../../res/Daze/exposition/daze7.jpg"

const infos ={
  photos: [
    { src: Daze3, name: "Daze" },
    { src: Daze5, name: "Daze" },
    { src: Daze6, name: "Daze" },
  ],
};

const Daze = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
      paritialVisibilityGutter: 0
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      paritialVisibilityGutter: 0
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      paritialVisibilityGutter: 0
    },
    mobile: {
      breakpoint: { max: 428, min: 0 },
      items: 1,
      paritialVisibilityGutter: 0
    },
  };
  return (
    <div className={Wrapper}>
      <div className={EventWrapper}>
        <img className={PdpWrapper} src={DazePdp} alt="daze"/>
        <div className={DescriptionWrapper}>
          <h1 className={TitleWrapper}>DAZE<h2 style={{paddingLeft: "16px"}}>40 ans de création - Marseille - 2020</h2></h1>
          <br />
          <img className={MobileTile} src={DazePdp} alt="daze"/>
          <br></br>
          <p>
            Marseille, le 5 novembre 2020. La GHOST galerie et le Château de Forbin, deux lieux d’art contemporain à Marseille - dédiés au graffiti et au Post-graffiti des années 80 - accueilleront du 5 novembre au 5 décembre 2020 la première « double » exposition de l’artiste new-yorkais Chris DAZE Ellis retraçant ses 40 ans de création artistique. Une rétrospective riche de plus de 60 oeuvres qui relate l’évolution de son travail sur l’ensemble de sa carrière.
          </p>
          <br></br>
          <br></br>
          <a className={DownloaderWrapper} href={DazePdf}download rel="noopener noreferrer" target="_blank">
              Télécharger le communiqué de presse
          </a>
        </div>
      </div>
      <br />
        
        <div className={PhotosWrapper}>
          <Title title={"Vues in situ"} />
          <div className={CarrouselWrapper2}>
          <Carousel
            slidesToSlide={1}
            arrows={true}
            swipeable={true}
            draggable={true}
            responsive={responsive}
            infinite={false}
            customTransition="transform 500ms ease-in-out"
            transitionDuration={500}
            autoPlay={true}
            autoPlaySpeed={3000}
            showDots={false}
            partialVisbile={true}
            >
            {/* TODO: Faire les cartel ici */}
            {infos.photos.map((image) => (
                <img
                  className={ImageWrapper}
                  key={image.name}
                  alt={image.name}
                  src={image.src}
                />
            ))}
          </Carousel>
          </div>
        </div>
        <br />
    </div>
  );
};

export default Daze;
